@import "~sanitize.css";

:root {
    --topbar-height: 58px;
    --topbar-main-height: 58px;
    --topbar-indicator-height: 40px;
    --topbar-mobile-indicator-height: calc(58px + 40px);
    --navbar-width: 226px;
    --sticky-alert-height: 32px;
    background-color: #fff;
    accent-color: #0000ff;
    line-height: 20px;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    padding: 0;
    margin: 0;
    font-synthesis: none;
    font-weight: 400;

    scrollbar-width: thin;
    scrollbar-color: #d4d4d4 white;

    --Spacing-1: 16px;
    --Spacing-2: 20px;
    --Spacing-3: 24px;
}

html {
    width: 100%;
    height: 100%;
}

#root {
    width: inherit;
    height: inherit;
}

body {
    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/** General styles */
h1 {
    line-height: 48px; /* 120% */
    letter-spacing: -0.8px;
}

h2 {
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
}

h3 {
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px;
}

h4 {
    line-height: 36px; /* 128.571% */
    letter-spacing: -0.56px;
}

h5 {
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
}

h6 {
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
}

code {
    font-family: "Jetbrains Mono", "Cascadia Code", "Monaco", "IBM Plex Mono",
        "Fira Code", monospace;
}
